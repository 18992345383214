<template>
<div >

	<h3><Narrative Test</h3>
	<q-expansion-item
        expand-separator
        icon="far fa-expand"
        label="Show JSON"
    >

		<pre>{{JSON.stringify(narrative,9,4)}}</pre>
	</q-expansion-item>

	<div class="content q-pa-md">

		<q-img :src="narrative.icon.url">
			<div class="absolute-bottom text-subtitle1 text-left">
            {{narrative.icon.caption}}
          </div>
        </q-img>

		<h3>{{narrative.title}}</h3>

		<strong>narrative.summary:</strong>


		<span v-for="n in narrative.summary">
			<div :class="n.type">
				<span v-for="c in n.content">{{c}}</span>
			</div>
		</span>

		<p><strong>narrative.content:</strong></p>

		<span v-for="n in narrative.content">

			<p>content-type: <strong>{{n.type}}</strong></p>

			<div class="chapter" v-if="n.type==='chapter'">
				<h4>
					<q-img style="max-width: 200px" :src="n.icon.url"/>
					{{n.title}}
				</h4>

				<span v-for="summary in n.summary">
					<div :class="summary.type">
						<span v-for="c in summary.content">{{c}}</span>
					</div>
				</span>
			</div>

			<div v-for="content in n.content">
				<li>content-types in this chapter: {{content.type}}</li>
				<ul>
					<li v-for="sub in content.content">content-types in this chapter.content: {{sub.type}}</li>
				</ul>

			</div>

			<div v-for="content in n.content">

				<p :class="content.type" v-if="content.type==='p'">{{content.text}}</p>

				<!-- SUBCHAPTER -->
				<div class="subchapter" v-if="content.type==='subchapter'">
					<h4>
						<q-img style="max-width: 200px" :src="content.icon.url"/>
						{{content.title}}
					</h4>

					<h5 v-if="content.related">Related:
					<span v-for="r in content.related">
						<q-btn color="primary" class="q-mr-md" type="a" :label="r.title" :href="r.url"/>
					</span>
					</h5>

					<span v-for="summary in n.summary">
						<div :class="summary.type">
							<span v-for="c in content.summary">{{c}}</span>
						</div>
					</span>

					<span v-for="sub in content.content">
						<div v-if="sub.type==='source'">
							<h5>{{sub.title}}</h5>
							<p>{{sub.description}}</p>

							<q-img class="q-mb-xl" :src="sub.asset.url">
								<div class="absolute-bottom text-subtitle1 text-left">
            						{{sub.caption}}
          						</div>
          					</q-img>
							<hr>
						</div>

						<div v-if="sub.type==='embed-youtube'">
							<iframe width="930" height="640" :src="sub.url" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
							<hr>
						</div>

						<div v-if="sub.type==='embed-tikitoki'">
								<iframe onmousewheel="" frameborder="0" style="border-width:0;" width="960" height="680" :src="sub.url"></iframe>


							<hr>
						</div>


						<p v-if="sub.type==='p'">{{sub.text}}</p>
					</span>



				</div>


				<div :class="content.type" v-else>
					<h3 style="color:red">no parser for: {{content.type}}</h3>



				</div>



			</div>
		</span>

	</div>


	<q-drawer class="aside" show-if-above elevated overlay :breakpoint="700" side="right">

		<h3>Title</h3>
		<p class="q-pa-md p">type: "p" - summary text</p>
		<p class="q-pa-md chapter">type: "chapter" - chapter</p>
	</q-drawer>




</div>
</template>
<script>

import narrative from '../../../narratives/ww1_narrative.json'
console.log("narrative loaded: ", narrative)


export default {

  setup(props, context) {

  	console.log("SETUP!!")

  	return {
  		narrative
  	}


  },

  methods: {
  },
  data() {
    return {
      showPerson: false
    }
  }
};
</script>


<style>

.p {
	background-color: #eee;
}

.code {
	font-face: courier;
	width:  80%;
	border:  2px solid grey;
	background-color: #eee;
	padding:  1em;
}

.chapter {
	background-color: #b3b3b3;

}

.subchapter {
	background-color: #cccccc;

}


.chapter-summary {
	background-color: lightgreen;
}



.content {
	max-width: 900px;
}

</style>